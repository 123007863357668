module.exports = {
  siteTitle: 'AlMonteAPernoctar', // <title>
  manifestName: 'AlMonteAPernoctar',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/favicon.ico',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Al Monte A Pernoctar',
  subHeading: 'Conocer el monte desde los ojos de sus amantes',

  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/JasonGamero',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url:
        'https://www.linkedin.com/in/jorge-jas%C3%B3n-gamero-corroto-36439b82/',
    },
  ],
  email: 'jotajason@gmail.com',
};
